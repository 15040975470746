import { EInsufficientFunds } from "@/types";

const useGameModal = () => {
	const { open: openPresetPackage } = usePresetPackageController();
	const { exitFullscreen } = useFullscreenToggle();
	const { open, close } = useAppModals();
	const { data: appInit } = useAppInitData();
	const { handleOpenGame } = useOpenGame(open);
	const { isSweepStakes } = useSwitchMode();
	const isGuest = useIsGuest();
	const { isFinOperationAvailable } = useCashHelper();
	const route = useRoute();
	const { sendAdTrigger } = usePopupAds();

	const { data: game, execute: getGame } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: {
				id: (route?.query?.game ?? "") as string,
				mode: !isGuest.value && route?.query?.isMoney ? "/play" : "/"
			}
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false
		}
	});

	const { games: allGamesResponse } = useGetGameFiltersData();
	const allGamesSorted = computed(() => allGamesResponse.value?.toSorted((a, b) => a.id - b.id) || []);
	const currentGameIndex = computed(() => allGamesSorted.value?.findIndex((g) => g.id === game.value?.game?.id));

	const hasPromoOffer = computed(() => !!appInit.value?.bannerPresetPackage);

	const insufficientFundsModalType = computed(() => {
		if (!isSweepStakes.value) {
			return hasPromoOffer.value ? EInsufficientFunds.TOURNAMENT_WITH_OFFER : EInsufficientFunds.NO_OFFER;
		}

		return hasPromoOffer.value ? EInsufficientFunds.SWEEPSTAKES_WITH_OFFER : EInsufficientFunds.NO_OFFER;
	});

	const openCashModal = () => {
		window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		if (!isFinOperationAvailable.value) {
			return;
		}
		openPresetPackage();
	};

	const openInsufficientFundsModal = () => {
		open("LazyOModalInsufficientFunds", { modalType: insufficientFundsModalType.value });
		dispatchGAEvent({
			type: isSweepStakes.value ? "winnings" : "coins",
			event: "open_popup",
			formName: "empty_balance",
			location: "gameplay"
		});
	};

	const closeModal = async () => {
		if (route.query.game) {
			sendAdTrigger("closeGame");
		}

		await navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
		close("LazyOModalMoneyBox");
	};

	const registerGameMessages = (event: MessageEvent) => {
		if (event.data.cash?.popups?.show) {
			exitFullscreen();
			openInsufficientFundsModal();
		}

		if (event.data.cash?.popups?.create) {
			const eventSource = event.source as typeof window.parent;
			eventSource.postMessage({ cash: { popups: { created: true } } }, "*");
		}

		if (event.data.cash?.open) {
			openCashModal();
		}

		if (event.data.game?.close) {
			closeModal();
		}
	};

	const switchGame = async (type: "previous" | "next") => {
		dispatchGAEvent({
			event: "click_button",
			button_name: type,
			location: "gameplay"
		});

		let newIndex = currentGameIndex.value;

		if (type === "previous") {
			newIndex = currentGameIndex.value === 0 ? allGamesSorted.value.length - 1 : currentGameIndex.value - 1;
		} else if (type === "next") {
			newIndex = currentGameIndex.value === allGamesSorted.value.length - 1 ? 0 : currentGameIndex.value + 1;
		}

		await closeModal();
		handleOpenGame(allGamesSorted.value[newIndex]?.slug);
	};

	watch(
		() => route.query.game,
		(value, oldValue) => {
			if (oldValue && value === undefined) {
				closeModal();
			}
		}
	);

	return {
		game,
		switchGame,
		openCashModal,
		getGame,
		registerGameMessages,
		closeModal
	};
};
export default useGameModal;
